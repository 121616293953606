import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null
}

const authToken = localStorage.getItem('token');
if(authToken && authToken !==''){
    initialState.token = authToken;
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUser: (state, action) => {
            const userToken = action.payload;
            state.token = userToken;
        },
        removeUser : (state, action) => {
            state.token = null;
        }
    },
})

export const { addUser, removeUser } = userSlice.actions;
export default userSlice.reducer;