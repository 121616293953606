import React from 'react'
import "./Loader.scss"

const Loader = () => {
  return (
    <div class="loader-overlay">
      <div class="loader"></div>
    </div>
  )
}

export default Loader