import { combineReducers } from "@reduxjs/toolkit";
import userReducer from '../slices/user-slice';
import sidebarReducer from '../slices/sidebar-slice';
import  UserIdReducer  from "../slices/user_id";

const rootReducer = combineReducers({
    user: userReducer,
    sidebar: sidebarReducer,
    userId: UserIdReducer,
})

export default rootReducer;