import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user_id: "",
}

export const UserIdSlice = createSlice({
    name: 'userId',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            const userId = action.payload;
            state.user_id = userId;
        }
    },
})

export const { setUserId } = UserIdSlice.actions;
export default UserIdSlice.reducer;