import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './App.css';
import SuspenseLoader from './shared/loaders/suspense-loader';
import { setUserId } from './redux/slices/user_id';
import axios from "axios";
import { setSidebarItem } from './redux/slices/sidebar-slice';
import "react-toastify/dist/ReactToastify.css";
import Loader from './shared/Loader/Loader';
import { getTokenData } from './utils/getDecodeToken/getTokenData';


// Modules import with lazy loading
const Login = lazy(() => import('./prelogin/login'));
const ForgotPassword = lazy(() => import('./prelogin/forgotpass'));
const Home = lazy(() => import('./postlogin/home'));

function PrivateRoute({ element }) {

  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? element : <Navigate to="/" replace />;
}

function PublicRoute({ element }) {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? <Navigate to="/home" replace /> : element;
}


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const decodedToken = getTokenData(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            localStorage.removeItem('token');
            window.location.href = "/";
          } else {
            dispatch(setUserId(decodedToken.user_id));
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          localStorage.removeItem('token');
          window.location.href = "/";

        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const handleActivity = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        localStorage.removeItem('token');
        window.location.href = "/";
      }, 1800000);
    };

    let idleTimeout = setTimeout(() => {
      localStorage.removeItem('token');
      window.location.href = "/";
    }, 1800000);

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);

    return () => {
      clearTimeout(idleTimeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible') {
  //       window.location.reload();
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    const checkTokenAndHandleVisibilityChange = () => {
      const token = localStorage.getItem('token');
      if (!token && document.visibilityState === 'visible') {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', checkTokenAndHandleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', checkTokenAndHandleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    dispatch(setSidebarItem(1));
  })

  return (
    <div className="app">
      {/* <Suspense fallback={<SuspenseLoader />}> */}
      <Suspense fallback={<Loader />}>
        <Router>
          <Routes>
          <Route path="/" element={<PublicRoute element={<Login />} />} />
          <Route path="/forgot-password" element={<PublicRoute element={<ForgotPassword />} />} />
            <Route path='/home/*' element={<PrivateRoute element={<Home />} />} />
          </Routes>
        </Router>
      </Suspense>
      <ToastContainer />

    </div>
  );
}

export default App;
