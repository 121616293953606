import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedSidebarItem: 1,
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarItem: (state, action) => {
            const itemId = action.payload;
            state.selectedSidebarItem = itemId;
        }
    },
})

export const { setSidebarItem } = sidebarSlice.actions;
export default sidebarSlice.reducer;