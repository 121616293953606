import { jwtDecode } from 'jwt-decode';

export const getTokenData = (token) => {
  try {
    if (!token || typeof token !== 'string') {
      throw new Error("Invalid token specified: must be a string");
    }
    return jwtDecode(token);
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};